import { Spin } from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

const LoadingData = () => {
  return (
    <div className="statescreens-container">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 60, color: '#8849D6' }} spin />} />
    </div>
  );
};

export default LoadingData;
