import { Input } from 'antd';
import { FC } from 'react';
import { CustomSimpleInputProps } from '../../customTypes';

export const LoginInputComp: FC<CustomSimpleInputProps> = ({ state, setState, ...props }) => {
  return (
    <>
      <Input
        className={`custom-input ${state.error !== '' ? 'with-error' : ''}`}
        {...props}
        value={state.value}
        onChange={
          props.onChange
            ? props.onChange
            : e =>
                setState({
                  ...state,
                  value: e.target.value,
                })
        }
      />
      {state.error !== '' ? <h1 className="error-text">{state.error}</h1> : <></>}
    </>
  );
};
