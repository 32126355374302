import { message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetEventsDataQuery, useGetGuestListQuery, useUpdateGuestListMutation } from '../../../graphql/types';
import { SimpleInputState } from '../../customTypes';
import { LoginInputComp } from '../login/Inputs';
import DatePicker from 'antd/lib/date-picker';
import moment, { Moment } from 'moment';
import { SelectProps } from 'antd';
import { SelectInputComp } from '../CreateEvents/Form/Inputs';

const TDatePicker: any = DatePicker;

interface EditGuestListProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  guestListId?: string;
  reload: () => void;
}

const EditGuestList: FC<EditGuestListProps> = ({ show, setShow, guestListId, reload }) => {
  const style = { height: 40 };
  const { t } = useTranslation('page');
  const [updateGuestList] = useUpdateGuestListMutation();
  const [name, setName] = useState<SimpleInputState>({
    value: '',
    error: '',
  });

  const [disabled, setDisabled] = useState<boolean>(false);
  const { data: guestListData } = useGetGuestListQuery({
    variables: {
      guestListId: guestListId || '',
    },
  });
  const [event, setEvent] = useState('');

  const clearState = () => {
    setEvent('');
    setName({
      value: '',
      error: '',
    });

    setStartTime(undefined);
    setEndTime(undefined);

    setDisabled(false);
  };

  const eventRef = useRef<SelectProps>(null);

  const events = useGetEventsDataQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        managedBySignedInBusinessUser: true,
      },
    },
  });

  const disabledDate = current => {
    return current < moment().subtract(1, 'day');
  };

  const [startTime, setStartTime] = useState<Moment>();
  const [endTime, setEndTime] = useState<Moment>();

  const handleSearch = async (input: string) => {
    await events.refetch({ search: input });
  };

  useEffect(() => {
    if (event !== '') {
      const eventJson = JSON.parse(event);
      const { startDate, endDate } = eventJson;

      setName({
        ...name,
        value: eventJson.name,
      });

      const startDateToMoment = startDate ? moment(startDate) : moment();
      const endDateToMoment = endDate ? moment(endDate) : startDateToMoment.add(8, 'hours');
      setStartTime(startDateToMoment);
      setEndTime(endDateToMoment);
    }
  }, [event]);

  useEffect(() => {
    if (guestListData?.guestList.guestList.title) {
      setName({
        value: guestListData?.guestList.guestList.title,
        error: '',
      });
    }

    if (guestListData?.guestList.guestList.startTime) {
      setStartTime(moment(guestListData?.guestList.guestList.startTime));
    }

    if (guestListData?.guestList.guestList.endTime) {
      setEndTime(moment(guestListData?.guestList.guestList.endTime));
    }
  }, [guestListData]);

  const handleSubmit = async () => {
    setDisabled(true);
    if (guestListId) {
      await updateGuestList({
        variables: {
          guestListId,
          input: {
            title: name.value,
            startTime,
            endTime,
            eventId: event !== '' && JSON.parse(event).id ? JSON.parse(event).id : null,
          },
        },
      })
        .then(() => {
          message.success('Guest list was edited!');
          setShow(false);
          clearState();
          reload();
        })
        .catch(err => {
          const msg = err.message ? err.message.toString() : 'Something went wrong, please try again later.';
          message.error(msg);
          setTimeout(() => setDisabled(false), 3000);
        });
    }
  };
  return (
    <Modal
      visible={show}
      onCancel={() => {
        setShow(!show);
        if (!guestListId) clearState();
      }}
      footer={
        <div className="modal-footer">
          <div
            className="button-left"
            onClick={() => {
              setShow(false);
              if (!guestListId) clearState();
            }}>
            {t('events.cancel')}
          </div>
          <div className={`button-right ${disabled ? 'disabled' : ''}`} onClick={() => handleSubmit()}>
            {t('events.submit')}
          </div>
        </div>
      }
      className="modal-container">
      <h1 className="title">{t('guestlist.edit')}</h1>

      <SelectInputComp
        value={event ? event : guestListData?.guestList.guestList.event ? guestListData?.guestList.guestList.event.name : ''}
        setValue={setEvent}
        eventID={undefined}
        editstring={''}
        noIcon={true}
        iconsrc=""
        placeholder={t('events.eventOptional')}
        bottomtext={t('events.eventOptional')}
        inputref={eventRef}
        optionsArray={
          events.data
            ? events.data.events.map(e => {
                return JSON.stringify({
                  name: e.name,
                  id: e.id,
                  startDate: e.startDate,
                  endDate: e.endDate,
                  coverImage: e.coverImageUrl,
                });
              })
            : []
        }
        showSearch
        onSearch={e => handleSearch(e)}
        loading={events.loading}
        isGuestList
        hasStaticEditstring={true}
        style={{
          width: '100%',
        }}
      />

      <LoginInputComp
        style={style}
        state={name}
        setState={setName}
        placeholder={t('guestlist.editName')}
        onChange={e =>
          setName({
            ...name,
            value: e.target.value,
          })
        }
        disabled={disabled}
      />

      <div className="modal-categories columnOnMobile">
        <div className="modal-categorie ">
          <TDatePicker
            showTime={{ format: 'HH:mm' }}
            format="DD.MM.YYYY HH:mm"
            disabledDate={disabledDate}
            onChange={value => setStartTime(value)}
            placeholder={t('guestlist.startDate')} // "Select start"
            style={{
              width: '100%',
              marginBottom: 8,
              borderRadius: 10,
              padding: '11px 18px',
            }}
            minuteStep={5}
            value={startTime}
            defaultValue={startTime}
          />
        </div>

        <div className="modal-categorie">
          <TDatePicker
            showTime={{ format: 'HH:mm' }}
            format="DD.MM.YYYY HH:mm"
            disabledDate={disabledDate}
            placeholder={t('guestlist.endDateOptional')}
            onChange={value => setEndTime(value)}
            style={{
              width: '100%',
              marginBottom: 8,
              borderRadius: 10,
              padding: '11px 18px',
            }}
            minuteStep={5}
            value={endTime}
            defaultValue={endTime}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditGuestList;
