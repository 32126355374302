import { FC, useEffect, useState } from 'react';
import { Avatar, Select, Switch } from 'antd';
import message from 'antd/lib/message';
import Input from 'antd/lib/input';

import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { Dresscode, EventType, PageCategory, useChangeEventDetailsMutation, useChangeVenueDetailsMutation } from '../../../../graphql/types';
import { CustomTextInputProps, CustomTextAreaProps, CustomSelectProps, CustomSwitchProps } from '../../../customTypes';
import dayjs from 'dayjs';
const { Option } = Select;
const { TextArea } = Input;

export const TextInputComp: FC<CustomTextInputProps> = ({
  value,
  setValue,
  editstring,
  iconsrc,
  placeholder,
  inputref,
  bottomtext,
  noIcon,
  noTextUnder,
  attending,
  interested,
  noMarginBottom,
  eventID,
  venueID,
  venueName,
  gqlField,
  newE,
  setNewE,
  reload,
  ...other
}) => {
  const [tempEditText, setTempEditText] = useState(editstring);
  const { t } = useTranslation('components');
  const [setEventDetails] = useChangeEventDetailsMutation();
  const [setVenueDetails] = useChangeVenueDetailsMutation();

  const onBlur = () => {
    blurInput(inputref);
    handleSetValue();
  };

  const focusInput = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current) ref.current.focus();
  };

  const blurInput = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current) ref.current.blur();
  };

  const handleSetValue = async () => {
    // if (other.type === 'number' && val && val !== '' && other.min && parseInt(val) >= other.min) {
    //     setValue(val);
    // }
    // if (val || val === '') {
    //     setValue(val);
    // }
    // if (val || val === '') {
    const isFloat = ['minimumEntrancePrice', 'minimumBeerPrice', 'minimumLongdrinkPrice'].includes(gqlField);
    const isInt = gqlField === 'minimumAge' || (gqlField === 'capacity' && venueID) || gqlField === 'checkoutTimer';
    const finalVal = isFloat ? parseFloat(value) : isInt ? parseInt(value) : value;

    if (eventID) {
      await setEventDetails({
        variables: {
          value: {
            [gqlField]: finalVal,
          },
          id: eventID,
        },
      })
        .then(() => {
          if (reload) reload();
        })
        .catch(err => {
          const msg = err.message ? err.message.toString() : 'Something went wrong, try again later.';
          message.error(msg);
        });
    } else if (venueID) {
      setVenueDetails({
        variables: {
          input: {
            [gqlField]: finalVal,
          },
          id: venueID,
        },
      })
        .then(() => {
          message.success('Saved');
          if (reload) reload();
        })
        .catch(() => message.error('Something went wrong, please try again later.'));
    } else if (newE && setNewE) {
      setNewE({
        ...newE,
        [gqlField]: finalVal,
      });
    }

    setTempEditText('Saved');
  };

  useEffect(() => {
    if (tempEditText === 'Saved') setTempEditText('Saved');
    else setTempEditText(editstring);
  }, [tempEditText]);

  return (
    <div className="create-input" onClick={() => focusInput(inputref)} style={noMarginBottom ? { marginBottom: 0 } : {}}>
      <div style={{ display: 'flex', alignItems: 'center', width: '70%' }}>
        {!noIcon && <Image src={iconsrc} layout="fixed" width={20} height={20} alt="PM_create_events" />}
        <div>
          <Input value={value} onChange={({ target }) => setValue(target.value)} placeholder={placeholder} onBlur={onBlur} ref={inputref} {...other} />
          {value !== '' && !noTextUnder && (
            <h2 className="placeholder" style={noIcon ? { marginLeft: 0 } : {}}>
              {bottomtext}
            </h2>
          )}
          {attending || (venueID && venueName) ? (
            <div className="attending">
              {attending && <Image id="users" src={'/icons/users.svg'} layout="fixed" width={20} height={20} alt="PM_attending" />}
              <h1>
                {venueID && venueName
                  ? `@${value.replace(/\s+/g, '_').toLowerCase()}`
                  : `${attending} ${t('inputs.attending')} • ${interested} ${t('inputs.interested')}`}
              </h1>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <h1 style={tempEditText == 'Saved' ? { color: '#8849D6', textTransform: 'uppercase' } : {}}>{tempEditText}</h1>
    </div>
  );
};
// text input

// text area input

export const TextAreaInputComp: FC<CustomTextAreaProps> = ({
  value,
  setValue,
  editstring,
  placeholder,
  inputref,
  bottomtext,
  noTextUnder,
  noIcon,
  noMarginBottom,
  eventID,
  gqlField,
  newE,
  setNewE,
  reload,
  attending,
  venueID,
  venueName,
  interested,
  ...other
}) => {
  const { t } = useTranslation('components');
  const [tempEditText, setTempEditText] = useState('');
  const [setEventDetails] = useChangeEventDetailsMutation();

  const onBlur = () => {
    blurInput(inputref);
    handleSetValue();
  };

  const focusInput = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current) ref.current.focus();
  };

  const blurInput = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current) ref.current.blur();
  };

  const handleSetValue = async () => {
    if (eventID) {
      setEventDetails({
        variables: {
          value: {
            [gqlField]: value,
          },
          id: eventID,
        },
      })
        .then(() => {
          if (reload) reload();
        })
        .catch(err => {
          const msg = err.message ? err.message.toString() : 'Something went wrong, try again later.';
          message.error(msg);
        });
    } else if (newE && setNewE) {
      setNewE({
        ...newE,
        [gqlField]: value,
      });
    }
    setTempEditText('Saved');
  };

  useEffect(() => {
    if (tempEditText === 'Saved') setTempEditText('Saved');
    else setTempEditText(editstring);
  }, [tempEditText]);

  return (
    <div className="create-input" onClick={() => focusInput(inputref)} style={noMarginBottom ? { marginBottom: 0 } : {}}>
      <div style={{ display: 'flex', alignItems: 'center', width: '90%' }}>
        <div style={{ width: '100%' }}>
          <TextArea value={value} onChange={({ target }) => setValue(target.value)} placeholder={placeholder} onBlur={onBlur} ref={inputref} {...other} />
          {value !== '' && !noTextUnder && (
            <h2 className="placeholder" style={noIcon ? { marginLeft: 0 } : {}}>
              {bottomtext}
            </h2>
          )}
          {attending || (venueID && venueName) ? (
            <div className="attending">
              {attending && <Image id="users" src={'/icons/users.svg'} layout="fixed" width={20} height={20} alt="PM_attending" />}
              <h1
                style={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '16px',
                  marginLeft: '7px',
                  letterSpacing: 0,
                }}>
                {venueID && venueName
                  ? `@${venueName.replace(/\s+/g, '_').toLowerCase()}`
                  : `${attending} ${t('inputs.attending')} • ${interested} ${t('inputs.interested')}`}
              </h1>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <h1 style={tempEditText == 'Saved' ? { color: '#8849D6', textTransform: 'uppercase' } : {}}>{tempEditText}</h1>
    </div>
  );
};
// text area input

// select input
export const SelectInputComp: FC<CustomSelectProps> = ({
  value,
  setValue,
  editstring,
  iconsrc,
  placeholder,
  inputref,
  bottomtext,
  optionsArray,
  eventID,
  newE,
  setNewE,
  venueID,
  noMarginBottom,
  noIcon,
  isGuestList,
  gqlField,
  hasStaticEditstring,
  ...other
}) => {
  const [tempEditText, setTempEditText] = useState(editstring);
  const [open, setOpen] = useState(false);
  const [setEventDetails] = useChangeEventDetailsMutation();
  const [setVenueDetails] = useChangeVenueDetailsMutation();

  const valueToDressCode = (val?: string): Dresscode => {
    return Dresscode[val ? val.replaceAll(' ', '') : value.replaceAll(' ', '')];
  };

  const valueToCategory = (val?: string): PageCategory => {
    return PageCategory[val ? val.replaceAll(' ', '') : value.replaceAll(' ', '')];
  };

  const valueToType = (val?: string): EventType => {
    return EventType[val ? val : value];
  };

  const onBlur = () => {
    blurInput(inputref);
    handleSetValue();
  };

  const focusInput = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current) ref.current.focus();
  };

  const blurInput = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current) ref.current.blur();
  };

  const handleSetValue = async (val?: string) => {
    if (val || val === '') setValue(val);
    if (val !== '') {
      if (eventID && gqlField) {
        setEventDetails({
          variables: {
            value: {
              [gqlField]: gqlField === 'dresscode' ? valueToDressCode() : valueToType(val),
            },
            id: eventID,
          },
        }).catch(err => {
          const msg = err.message ? err.message.toString() : 'Something went wrong, try again later.';
          message.error(msg);
        });
      } else if (venueID) {
        setVenueDetails({
          variables: {
            input: {
              dresscode: valueToDressCode(),
              category: valueToCategory(),
            },
            id: venueID,
          },
        })
          .then(() => message.success('Saved'))
          .catch(() => message.error('Something went wrong, please try again later.'));
      } else if (newE && setNewE) {
        setNewE({
          ...newE,
          dresscode: valueToDressCode(),
        });
      }

      if (!hasStaticEditstring) setTempEditText('Saved');
    } else setTempEditText(editstring);
  };

  return (
    <div className="create-input" onClick={() => focusInput(inputref)} style={noMarginBottom ? { marginBottom: 0 } : {}}>
      <div style={{ display: 'flex', alignItems: 'center', width: hasStaticEditstring && editstring === '' ? '100%' : '70%' }}>
        {!noIcon && <Image src={iconsrc} layout="fixed" width={20} height={20} alt="PM_select" />}
        <div style={hasStaticEditstring && editstring === '' ? { width: '100%' } : {}}>
          <Select
            placeholder={placeholder}
            ref={inputref}
            onBlur={() => {
              onBlur();
              setOpen(false);
            }}
            value={value !== '' ? value : null}
            onChange={e => {
              handleSetValue(e);
              setOpen(false);
            }}
            className="dresscode-select"
            dropdownClassName="dresscode-select-option"
            open={open}
            onFocus={() => setOpen(true)}
            {...other}>
            {optionsArray.map((e, index) => (
              <Option value={e} key={index} className="select-option">
                {isGuestList && JSON.parse(e).coverImage ? <Avatar style={{ marginRight: 10 }} src={JSON.parse(e).coverImage} /> : <></>}
                {isGuestList ? JSON.parse(e).name : e}
                {isGuestList && JSON.parse(e).startDate ? (
                  ` From ${dayjs(JSON.parse(e).startDate).format('DD MMM HH:mm')} ${
                    JSON.parse(e).endDate ? `to ${dayjs(JSON.parse(e).endDate).format('DD MMM HH:mm')}` : ''
                  }`
                ) : (
                  <></>
                )}
              </Option>
            ))}
          </Select>

          {value !== '' && <h2 className="placeholder">{bottomtext}</h2>}
        </div>
      </div>
      {hasStaticEditstring && editstring === '' ? (
        <></>
      ) : (
        <h1 style={tempEditText == 'Saved' ? { color: '#8849D6', textTransform: 'uppercase' } : {}}>{tempEditText}</h1>
      )}
    </div>
  );
};

export const SwitchInputComp: FC<CustomSwitchProps> = ({
  value,
  setValue,
  mainText,
  secondText,
  gqlField,
  venueID,
  noMarginBottom,
  hideSwitch,
  rightText,
  eventID,
  ...other
}) => {
  const [setVenueDetails] = useChangeVenueDetailsMutation();
  const [updateEvent] = useChangeEventDetailsMutation();

  const handleChange = async () => {
    setValue(!value);

    if (gqlField) {
      if (venueID) {
        await setVenueDetails({
          variables: {
            input: {
              [gqlField]: value,
            },
            id: venueID,
          },
        })
          .then(() => {
            setValue(!value);
          })
          .catch(() => message.error('Something went wrong, please try again later.'));
      }

      if (eventID) {
        await updateEvent({
          variables: {
            id: eventID,
            value: {
              [gqlField]: !value,
            },
          },
        })
          .then(() => {
            setValue(!value);
          })
          .catch(() => message.error('Something went wrong, please try again later.'));
      }
    }
  };
  return (
    <div className="create-input" style={noMarginBottom ? { marginBottom: 0 } : {}} onClick={handleChange}>
      <div>
        {mainText && <h3 className="mainText">{mainText}</h3>}
        {secondText && (
          <h2 className="placeholder" style={{ margin: 0 }}>
            {secondText}
          </h2>
        )}
      </div>

      {hideSwitch ? <h1>{rightText}</h1> : <Switch checked={value} onChange={handleChange} checkedChildren="ON" unCheckedChildren="OFF" />}
    </div>
  );
};
