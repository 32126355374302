import { FC, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import message from 'antd/lib/message';
import {
  EventStatus,
  GuestStatus,
  useChangeEventDetailsMutation,
  useChangeGuestStatusMutation,
  useDeleteBusinessUserMutation,
  useDeleteEventMutation,
  useDeleteGuestListMutation,
} from '../../../graphql/types';
import EditGuestList from '../GuestList/EditGuestList';
import Modal from 'antd/lib/modal/Modal';

interface ThreeDotProps {
  open: 'open' | 'close';
  handleOpen: () => void;
  isPublish?: boolean;
  eventId?: string;
  accountId?: string;
  guestListId?: string;
  guest?: any;
  isAccounts?: boolean;
  eventStatus?: EventStatus;
  reload: () => void;
  isGuestList?: boolean;
  top?: string;
  isPages?: boolean;
  isEvents?: boolean;
}

const ThreeDot: FC<ThreeDotProps> = ({
  open,
  handleOpen,
  isPublish,
  eventId,
  guest,
  isGuestList,
  eventStatus,
  isAccounts,
  accountId,
  guestListId,
  reload,
  top,
  isEvents,
}) => {
  const { t } = useTranslation('page');
  const [changeGuestStatus] = useChangeGuestStatusMutation();
  const [updateChangeEventDetails] = useChangeEventDetailsMutation();
  const [deleteEvent] = useDeleteEventMutation();
  const [deleteBusinessUser] = useDeleteBusinessUserMutation();
  const [deleteGuestList] = useDeleteGuestListMutation();
  const [showEditGuestList, setShowEditGuestList] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [confirmEventDelete, setConfirmEventDelete] = useState<boolean>(false);
  const guestRowClassName = (status: string): string => {
    const guestCheck = guest && guest.status && status === guest.status.toString();
    const eventCheck = eventStatus && status === eventStatus.toString();
    const disabled = guestCheck || eventCheck;
    return disabled ? 'disabled' : '';
  };

  const guestRowOnClick = async (guestStatus?: GuestStatus, eventStatus?: EventStatus) => {
    const guestCheck = guest && guest.status && guestStatus && guestStatus !== guest.status;
    if (guestCheck) {
      await changeGuestStatus({
        variables: {
          guestID: guest.id,
          status: guestStatus,
        },
      })
        .then(() => reload())
        .catch(err => {
          const msg = err.message ? err.message.toString() : 'Something went wrong, please try again later.';
          message.error(msg);
        });
    } else {
      await updateChangeEventDetails({
        variables: {
          id: eventId ? eventId : '',
          value: {
            status: eventStatus,
          },
        },
      })
        .then(() => reload())
        .catch(err => {
          const msg = err.message ? err.message.toString() : 'Something went wrong, please try again later.';
          message.error(msg);
        });
    }
  };

  const handleDelete = async () => {
    if (isAccounts && accountId) {
      await deleteBusinessUser({
        variables: {
          id: accountId,
        },
      })
        .then(() => {
          message.success('Business user was deleted!');
          reload();
        })
        .catch(err => {
          const msg = err.message ? err.message.toString() : 'Something went wrong, please try again later.';
          message.error(msg);
        });
    }

    if (isEvents && eventId) {
      await deleteEvent({
        variables: {
          eid: eventId,
        },
      })
        .then(() => {
          message.success('The event was deleted succesfully.');
          reload();
        })
        .catch(err => {
          const msg = err.message ? err.message.toString() : 'Something went wrong, please try again later.';
          message.error(msg);
        });
    }
  };

  const handleDeleteGuestList = async () => {
    if (guestListId) {
      setLoading(true);
      await deleteGuestList({
        variables: {
          guestListId,
        },
      })
        .then(() => {
          message.success('Guest list was deleted!');
          reload();
        })
        .catch(err => {
          const msg = err.message ? err.message.toString() : 'Something went wrong, please try again later.';
          message.error(msg);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <div className={`three-dot ${open}`} onClick={handleOpen}>
        <div className="three-dot-icons">
          <Image src={'/icons/three_dot_circle.svg'} alt="PM_dropdown" layout="fixed" width={30} height={30} />
        </div>

        <div
          className="three-dot-actions"
          style={{
            top,
          }}>
          {isAccounts ? (
            <>
              <Link href={'/accounts/[id]'} as={`/accounts/${accountId}`} passHref>
                <div className="actions-row">
                  <Image src={'/icons/edit_pen.svg'} width={18} height={18} layout="fixed" alt="PM_edit_event" />
                  <h1>{t('events.edit')}</h1>
                </div>
              </Link>

              <div className="actions-row red" onClick={handleDelete}>
                <Image src={'/icons/bin.svg'} width={18} height={18} layout="fixed" alt="PM_delete_event" />
                <h1>{t('events.delete')}</h1>
              </div>
            </>
          ) : isGuestList ? (
            <>
              <div className="actions-row" onClick={() => setShowEditGuestList(true)}>
                <Image src={'/icons/edit_pen.svg'} width={18} height={18} layout="fixed" alt="PM_edit_event" />
                <h1>{t('events.edit')}</h1>
              </div>

              <div className={`actions-row red ${guestRowClassName('Declined')}`} onClick={() => setConfirmModal(true)}>
                <CloseCircleOutlined style={{ color: '#FF427E' }} />
                <h1>Delete</h1>
              </div>
            </>
          ) : (
            <>
              {!isPublish && (
                <Link href={'/events/[id]'} as={`/events/${eventId}`} passHref>
                  <div className="actions-row">
                    <Image src={'/icons/edit_pen.svg'} width={18} height={18} layout="fixed" alt="PM_edit_event" />
                    <h1>{t('events.edit')}</h1>
                  </div>
                </Link>
              )}

              {isEvents ? (
                <>
                  <div className={`actions-row ${guestRowClassName('Published')}`} onClick={() => guestRowOnClick(undefined, EventStatus.Published)}>
                    <Image src={'/icons/eye_open.svg'} width={18} height={18} layout="fixed" alt="PM_publish_event" />
                    <h1>{t('events.publish')}</h1>
                  </div>
                  {/* <div className={`actions-row ${guestRowClassName('Draft')}`} onClick={() => guestRowOnClick(undefined, EventStatus.Draft)}>
                    <Image src={eye_close} width={18} height={18} layout="fixed" alt="PM_unpublish_event" />
                    <h1>{t('events.unpublish')}</h1>
                  </div> */}
                  {/* <div className={`actions-row ${guestRowClassName('Cancel')}`} onClick={() => guestRowOnClick(undefined, EventStatus.Canceled)}>
                    <Image src={close} width={18} height={18} layout="fixed" alt="PM_cancel_event" />
                    <h1>{t('events.cancel')}</h1>
                  </div> */}

                  <div className="actions-row red" onClick={() => setConfirmEventDelete(true)}>
                    <Image src={'/icons/bin.svg'} width={18} height={18} layout="fixed" alt="PM_delete_event" />
                    <h1>{t('events.delete')}</h1>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>

      {isGuestList && showEditGuestList ? (
        <EditGuestList guestListId={guestListId} reload={reload} show={showEditGuestList} setShow={setShowEditGuestList} />
      ) : (
        <></>
      )}

      <Modal
        onCancel={() => setConfirmEventDelete(false)}
        visible={confirmEventDelete}
        footer={
          <div className="modal-footer">
            <div className="button-left" onClick={() => setConfirmEventDelete(false)}>
              {t('events.cancel')}
            </div>
            <div className={`button-right ${loading ? 'disabled' : ''}`} onClick={handleDelete}>
              {t('events.submit')}
            </div>
          </div>
        }
        className="modal-container">
        <h1 className="title">{t('events.areYouSure')}</h1>
      </Modal>

      <Modal
        onCancel={() => setConfirmModal(false)}
        visible={confirmModal}
        footer={
          <div className="modal-footer">
            <div className="button-left" onClick={() => setConfirmModal(false)}>
              {t('events.cancel')}
            </div>
            <div className={`button-right ${loading ? 'disabled' : ''}`} onClick={handleDeleteGuestList}>
              {t('events.submit')}
            </div>
          </div>
        }
        className="modal-container">
        <h1 className="title">{t('guestlist.areYouSure')}</h1>
      </Modal>
    </>
  );
};

export default ThreeDot;
