import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mainPages } from '../../customTypes';
import { checkPage } from '../../utils';

interface NoDataProps {
  page: mainPages;
}

const NoData: FC<NoDataProps> = ({ page }) => {
  const { t } = useTranslation('components');

  const text = (isDesc?: boolean) => {
    return t(
      `stateScreen.${
        checkPage(page).isEvents
          ? 'events.noEvents'
          : checkPage(page).isGuests
          ? 'guests.noGuests'
          : checkPage(page).isAccounts
          ? 'accounts.noAccounts'
          : checkPage(page).isFestivals
          ? 'festivals.noFestivals'
          : checkPage(page).isPromoters
          ? 'promoters.noPromoters'
          : checkPage(page).isGuestList
          ? 'guestlist.noGuestList'
          : checkPage(page).isCrew
          ? 'crew.noCrew'
          : 'venues.noVenues'
      }${isDesc ? 'Desc' : ''}`,
    );
  };

  return (
    <div className="statescreens-container">
      {checkPage(page).isEvents ? (
        <svg width="248" height="122" viewBox="0 0 248 122" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="91.1465" width="156.026" height="37.9801" rx="3.46273" fill="#E7E9ED" />
          <rect x="100.936" y="10.498" width="7.83424" height="7.83424" rx="3.91712" fill="#D7DAE1" />
          <rect x="100.936" y="25.1367" width="117.514" height="4.78759" rx="1.73136" fill="#D7DAE1" />
          <rect x="113.994" y="10.4961" width="87.0471" height="7.83424" rx="1.73136" fill="#D7DAE1" />
          <g filter="url(#filter0_d)">
            <rect x="22" y="34.8242" width="196.241" height="61.3937" rx="4.36113" fill="white" />
          </g>
          <rect x="34.0605" y="47.9785" width="9.86684" height="9.86684" rx="4.93342" fill="#BB86FC" />
          <rect x="34.0605" y="67.7129" width="148.003" height="6.02974" rx="2.18057" fill="#E2E2EB" />
          <rect x="34.0605" y="78.127" width="121.143" height="6.02974" rx="2.18057" fill="#E2E2EB" />
          <rect x="50.5059" y="47.9785" width="109.632" height="9.86684" rx="2.18057" fill="#E2E2EB" />
          <defs>
            <filter id="filter0_d" x="0.425787" y="16.8457" width="239.389" height="104.542" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="3.5957" />
              <feGaussianBlur stdDeviation="10.7871" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
          </defs>
        </svg>
      ) : checkPage(page).isGuests ? (
        <svg viewBox="64 64 896 896" focusable="false" width="248" height="122" fill="currentColor" aria-hidden="true">
          <path d="M892 772h-80v-80c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v80h-80c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h80v80c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-80h80c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM373.5 498.4c-.9-8.7-1.4-17.5-1.4-26.4 0-15.9 1.5-31.4 4.3-46.5.7-3.6-1.2-7.3-4.5-8.8-13.6-6.1-26.1-14.5-36.9-25.1a127.54 127.54 0 01-38.7-95.4c.9-32.1 13.8-62.6 36.3-85.6 24.7-25.3 57.9-39.1 93.2-38.7 31.9.3 62.7 12.6 86 34.4 7.9 7.4 14.7 15.6 20.4 24.4 2 3.1 5.9 4.4 9.3 3.2 17.6-6.1 36.2-10.4 55.3-12.4 5.6-.6 8.8-6.6 6.3-11.6-32.5-64.3-98.9-108.7-175.7-109.9-110.8-1.7-203.2 89.2-203.2 200 0 62.8 28.9 118.8 74.2 155.5-31.8 14.7-61.1 35-86.5 60.4-54.8 54.7-85.8 126.9-87.8 204a8 8 0 008 8.2h56.1c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5 29.4-29.4 65.4-49.8 104.7-59.7 3.8-1.1 6.4-4.8 5.9-8.8zM824 472c0-109.4-87.9-198.3-196.9-200C516.3 270.3 424 361.2 424 472c0 62.8 29 118.8 74.2 155.5a300.95 300.95 0 00-86.4 60.4C357 742.6 326 814.8 324 891.8a8 8 0 008 8.2h56c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5C505.8 695.7 563 672 624 672c110.4 0 200-89.5 200-200zm-109.5 90.5C690.3 586.7 658.2 600 624 600s-66.3-13.3-90.5-37.5a127.26 127.26 0 01-37.5-91.8c.3-32.8 13.4-64.5 36.3-88 24-24.6 56.1-38.3 90.4-38.7 33.9-.3 66.8 12.9 91 36.6 24.8 24.3 38.4 56.8 38.4 91.4-.1 34.2-13.4 66.3-37.6 90.5z" />
        </svg>
      ) : (
        <svg width="168" height="154" viewBox="0 0 168 154" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="58" y="44" width="110" height="73" rx="4.36113" fill="#E7E9ED" />
          <rect x="102" y="54" width="14" height="14" rx="2.18057" fill="#D7DAE1" />
          <rect x="122" y="54" width="14" height="14" rx="2.18057" fill="#D7DAE1" />
          <rect x="142" y="54" width="14" height="14" rx="2.18057" fill="#D7DAE1" />
          <path d="M116 99.1806C116 97.9763 116.976 97 118.181 97H139.819C141.024 97 142 97.9763 142 99.1806V117H116V99.1806Z" fill="#D7DAE1" />
          <rect x="102" y="74" width="14" height="14" rx="2.18057" fill="#D7DAE1" />
          <rect x="122" y="74" width="14" height="14" rx="2.18057" fill="#D7DAE1" />
          <rect x="142" y="74" width="14" height="14" rx="2.18057" fill="#D7DAE1" />
          <g filter="url(#filter0_d_1629_15573)">
            <rect x="22" y="18" width="72" height="110" rx="4.36113" fill="white" />
          </g>
          <rect x="31" y="46" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <rect x="51" y="46" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <rect x="71" y="46" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <rect x="31" y="26" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <rect x="51" y="26" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <rect x="71" y="26" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <rect x="31" y="66" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <rect x="51" y="66" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <rect x="71" y="66" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <path d="M45 111.181C45 109.976 45.9763 109 47.1806 109H68.8194C70.0237 109 71 109.976 71 111.181V129H45V111.181Z" fill="#E2E2EB" />
          <rect x="31" y="86" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <rect x="51" y="86" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <rect x="71" y="86" width="14" height="14" rx="2.18057" fill="#E2E2EB" />
          <defs>
            <filter
              id="filter0_d_1629_15573"
              x="0.425787"
              y="0.0214891"
              width="115.148"
              height="153.148"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="3.5957" />
              <feGaussianBlur stdDeviation="10.7871" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1629_15573" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1629_15573" result="shape" />
            </filter>
          </defs>
        </svg>
      )}
      <h1>{text()}</h1>
      <p>{text(true)}</p>
    </div>
  );
};

export default NoData;
